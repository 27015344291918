// ** JS MAIN FILE ** //
window.addEventListener("resize", () => {
    if (window.innerWidth >= 768) {
        window.location.reload();
    }
});
window.onbeforeunload = function () {
    window.scrollTo(0, 0);
};
window.scrollTo(0, 0);
window.addEventListener('DOMContentLoaded', () => window.scrollTo(0, 0));

// Import modules
import { animations } from './modules/_animations.js';
animations();
import { menu } from './modules/_menu.js';
menu();

// Import templates
import { homeAnimations } from './templates/_homeAnimations.js';
homeAnimations();
